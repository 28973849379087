import React, { useState } from "react";
import "./siderbar.css";
import { NavLink, useLocation } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";
import { IoIosLogOut } from "react-icons/io";

function SideBar() {
  const location = useLocation();
  return (
    <>
      <button
        class="btn btnhidden"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling"
        aria-controls="offcanvasScrolling"
      >
        <IoMdMenu size={25} />
      </button>

      <div
        class="offcanvas offcanvas-start "
        style={{ background: "pink", width: "80%" }}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabindex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <NavLink className="side mt-3 ps-2" to="/home">
            <IoHomeOutline size={25} />
            <li
              className={
                "pt-2 p-2 " +
                (location.pathname === "/home" ? "active" : "inactive")
              }
              style={{ textDecoration: "none", color: "#C71585" }}
            >
              Home 
            </li>
          </NavLink>
          <NavLink className="side mt-3 ps-2" to="/Upload">
            <MdOutlineFileUpload size={35} />
            <li
              className={
                "pt-2 p-2 " +
                (location.pathname === "/Upload" ? "active" : "inactive")
              }
              style={{ textDecoration: "none", listStyle: "none" }}
            >
              Add Category
            </li>
          </NavLink>
          <NavLink className="side mt-3 ps-2" to="/AddSubCategoryData">
            <MdOutlineFileUpload size={35} />
            <li
              className={
                "pt-2 p-2 " +
                (location.pathname === "/AddSubCategoryData"
                  ? "active"
                  : "inactive")
              }
              style={{ textDecoration: "none", listStyle: "none" }}
            >
              Add Sub Category
            </li>
          </NavLink>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-12 col-sm-12 col-md-12 hideside"
            style={{ overflowY: "auto" }}
          >
            <NavLink className="side mt-3 ps-2" to="/home">
              <IoHomeOutline size={35} />
              <li
                className={
                  "pt-2 p-2 " +
                  (location.pathname === "/home" ? "active" : "inactive")
                }
                style={{
                  textDecoration: "none",
                  fontSize: 13,
                  color: "#fff",
                }}
              >
                Home 
              </li>
            </NavLink>
            <NavLink className="side mt-3 ps-2" to="/Upload">
              <MdOutlineFileUpload size={35} />
              <li
                className={
                  "pt-2 p-2 " +
                  (location.pathname === "/Upload" ? "active" : "inactive")
                }
                style={{
                  textDecoration: "none",
                  listStyle: "none",
                  fontSize: 13,
                  color: "#fff",
                }}
              >
                Add Category
              </li>
            </NavLink>
            <NavLink className="side mt-3 ps-2" to="/AddSubCategoryData">
              <MdOutlineFileUpload size={35} />
              <li
                className={
                  "pt-2 p-2 " +
                  (location.pathname === "/AddSubCategoryData"
                    ? "active"
                    : "inactive")
                }
                style={{
                  textDecoration: "none",
                  listStyle: "none",
                  fontSize: 13,
                  color: "#fff",
                }}
              >
                Add Sub Category
              </li>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
