import React, { useState } from 'react';
import logo from '../Compontes/img/logo.png';
import SiderBar from './SiderBar';
import Dashboard from './Dashboard';
import { CiSearch } from "react-icons/ci";
import './Home.css';
import UploadData from './UploadData';
function NewCategory() {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      

      <div className='container-fluid' >
        <div className='row'>
        <div className='col-lg-2' style={{ background: "#000"}} >
        <SiderBar/>
      </div>
          <div className='col-lg-10' style={{ backgroundColor: "White" }}>
           <UploadData/>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCategory;
