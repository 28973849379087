import React, { useState } from "react";
import SideBar from "./SiderBar";
import logo from './img/logo.png';
import UploadData from "./UploadData";
import { CiSearch } from "react-icons/ci";
import AddCategory from "./AddCategory";
function Upload() {
  const [isFocused, setIsFocused] = useState(false);

  return <div>
    

  <div className="container-fluid">
  <div className="row">
  <div className="col-lg-2" style={{ background: "#000"}}>
  <SideBar/>
  </div>
  <div className="col-lg-10">
  <AddCategory/>
  </div> 
  </div>
  </div>
  
  </div>;
}

export default Upload;
