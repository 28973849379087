import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
function Dashboard() {
  const [data, setData] = useState([]);

  const getAllUsers = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage?.getItem('token')}`
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

     await fetch(
        "https://newapi.jaishreeramhealthcareprivateltd.com/admin/getAllUsers",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setData(result.data))
        .catch((error) => console.error(error));
    } catch (error) {
      console.log("Error fetching users", error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage?.getItem('token')}`);

      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

     await fetch(
        `https://newapi.jaishreeramhealthcareprivateltd.com/admin/users/${id}`,
        requestOptions
      )
        .then((response) =>{ return response.json()})
        .then((result) => {
          console.log("Delete Result:", result);
          if (result.status === "001") {
            getAllUsers();
          } else {
            console.log("Error deleting data");
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {}
  };

  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <>
      <div style={{ height: "88vh", overflowY: "scroll" }}>
        <div className="container1" style={{ marginBottom: "240px" }}>
          <div className="row mt-4 p-0 m-0">
            <div className="col-lg-12 col-sm-12 col-md-12 p-0">
              <div
                className="card"
                style={{ color: "white", width: "100%" }}
              >
                <div
                  className="card-header mb-0 pb-0"
                  style={{ background: "1", }}
                >
                  <h6 style={{ color: "black", fontSize: 21 }}>Users</h6>
                </div>
                <div className="card-body p-0">
                  <div style={{ overflowX: "auto", overflowY: "auto" }}>
                    <table
                      className="table"
                      style={{ color: "black" }}
                    >
                      <tr className="ps-5" >
                        <th className="ps-2" >
                          sn
                        </th>
                      
                        <th className="ps-2" >
                        FullName
                        </th>
                        <th className="ps-2" >
                          Email
                        </th>
                        <th className="ps-2" >
                          Mobile
                        </th>
                        <th className="ps-2" >
                          Gender
                        </th>
                        <th className="ps-2" >
                          Age
                        </th>
                      
                           <th className="ps-5 pe-5" >
                                                    Address

                        </th>
                        <th className="ps-2" >
                          BloodGroup
                        </th>
                        <th className="ps-2" >
                          Profession
                        </th>
                        <th className="ps-2" >
                          HasInsurance
                        </th>
                        <th className="ps-2" >
                          insuranceType
                        </th>
                        <th className="ps-2" >
                          insuranceCompany
                        </th>
                        <th className="ps-2" >
                          Del
                        </th>
                      </tr>

                      {data?.map((res, index) => (
                        <tr
                          key={res._id}
                          className="ps-2"
                        >
                          <td className="ps-2" style={{ border: "1" }}>
                            {index + 1}
                          </td>

                          <td className="ps-2" style={{ border: "1" }}>
                            {res.fullName}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.email}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.mobile}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.gender}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.age}
                          </td>
                          <td className="ps-2" style={{ border: "1" , width:'10px', overflow:'scroll'}}>
                            {res.address}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.bloodGroup}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.profession}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.hasInsurance}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.insuranceType}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.insuranceCompany}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            <MdDeleteOutline onClick={()=>{
                              deleteUser(res._id)
                            }}
                            style={{cursor: "pointer",backgroundColor:"#ff69b4",borderRadius:"10px"}}
                             className="fs-1  text-white" />
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        '
      </div>
    </>
  );
}

export default Dashboard;
