// import React, { useState } from "react";
// import './Home.css';
// import logo from '../Compontes/img/logo.png';
// import { useNavigate } from "react-router-dom";

// function Login() {
//   const navigation = useNavigate()
//   const [contact, setcontact] = useState("")
//   const [password, setPassword] = useState("")

//   const loginAdmin = () => {
//     try {

//       const myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");

//       const raw = JSON.stringify({
//         "contact": "8900000001",
//         "password": "admin@jsr"
//       });

//       const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow"
//       };

//       fetch("https://api.jaishreeramhealthcareprivateltd.com/users/login", requestOptions)
//         .then((response) => response.json())
//         .then((result) => console.log(result))
//         .catch((error) => console.error(error));

//     } catch (error) {

//     }
//   }

//   return <div>
//     <div class="login-container">
//       <div class="login-box">
//         <img src={logo} alt="Logo" class="logo" />
//         <form>
//           <div class="input-group">
//             <label for="email">Email Address</label>
//             <input type="email" id="email" placeholder="Email Address" />
//           </div>
//           <div class="input-group">
//             <label for="password">Password</label>
//             <input type="password" id="password" placeholder="Password" />
//             <a href="#" class="forgot-password">Forgot?</a>
//           </div>
//           <div class="checkbox-group">
//             <input type="checkbox" id="rememberMe" />
//             <label for="rememberMe">Remember Me</label>
//           </div>
//           <button type="submit" onClick={() => navigation("home")} class="login-button">Log In</button>
//         </form>
//       </div>
//     </div>


//   </div>;
// }

// export default Login;

// import React, { useState } from "react";
// import './Home.css';
// import logo from '../Compontes/img/logo.png';
// import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// function Login() {
//   const navigation = useNavigate();
//   const [contact, setContact] = useState("");
//   const [password, setPassword] = useState("");
//   const [email, setEmail] = useState("");
//   const[loading,setloading]=useState(false)
//   const [errorMessage, setErrorMessage] = useState("");

 

//   const loginAdmins = async () => {
//     setloading(true)
//     try {
//       const myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");

//       const raw = JSON.stringify({
//         "email": email,
//         "password": password
//       });

//       const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow"
//       };

//       fetch("http://api.jaishreeramhealthcareprivateltd.com/admin/login", requestOptions)
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "001") {
//             toast.success(result.message, {
//               autoClose: 3000,
//             })
//             navigation("/home");
//             setEmail("")
//             setPassword("")
//           } else {
//             toast.error(result.message, {
//               autoClose: 2000
//             })
//           }
//         }).finally(()=>{
//           setloading(false)
//         })
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   return (
//     <div className="login-container">
//       <div className="login-box">
//         <img src={logo} alt="Logo" className="logo" />
//         <form onSubmit={loginAdmins}>
//           <div className="input-group">
//             <label htmlFor="contact">Email</label>
//             <input
//               type="email"
//               id="email"
//               placeholder="Enter Your Email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>
//           <div className="input-group">
//             <label htmlFor="password">Password</label>
//             <input
//               type="password"
//               id="password"
//               placeholder="Password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <a href="#" className="forgot-password">Forgot?</a>
//           </div>
//           <div className="checkbox-group">
//             <input type="checkbox" id="rememberMe" />
//             <label htmlFor="rememberMe">Remember Me</label>
//           </div>
//           <button type="submit" className="login-button">Log In</button>
//         </form>
//         {errorMessage && <p className="error-message">{errorMessage}</p>}
//       </div>
//     </div>
//   );
// }

// export default Login;

import React, { useState } from "react";
import './Home.css';
import logo from '../Compontes/img/logo.png';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Add this if not included

function Login() {
  const navigate = useNavigate();
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const loginAdmins = async (e) => {
    e.preventDefault(); // Prevent form submission

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.", { autoClose: 3000 });
      return;
    }

    if (password === "") {
      toast.error("Password is required.", { autoClose: 3000 });
      return;
    }

    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "email": email,
        "password": password
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch("https://newapi.jaishreeramhealthcareprivateltd.com/admin/login", requestOptions);
      const result = await response.json();

      if (result.status === "001") {
        toast.success(result.message, { autoClose: 3000 });
        localStorage.setItem("token",result?.token)
        navigate("/home");
      } else {
        toast.error(result.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again.", { autoClose: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="logo" />
        <form onSubmit={loginAdmins}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))} // Remove spaces
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <a href="#" className="forgot-password">Forgot?</a>
          </div>
          <div className="checkbox-group">
            <input type="checkbox" id="rememberMe" />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? <div className="loader"></div> : "Log In"}
          </button>
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
