import './App.css';
import { Routes, Route, NavLink, useLocation, Navigate, useNavigate } from "react-router-dom";
import Home from './Compontes/Home';
import Upload from './Compontes/Upload';
import logo from '../src/Compontes/img/jai.png';
import { CiSearch } from "react-icons/ci";
import { useState } from 'react';
import { IoIosLogOut } from "react-icons/io";
import Login from './Compontes/Login';
import AddSubCategoryData from './Compontes/AddSubCategoryData';
import AddCategory from './Compontes/AddCategory';
import NewCategory from './Compontes/NewCategory';

function App() {
   const navigate = useNavigate()
  const [isFocused, setIsFocused] = useState(false);
  const location = useLocation();
  const isAuth = localStorage.getItem("token")
 // Example authentication check from Redux (or any state management)
 console.log(localStorage.getItem("token"), isAuth, 'isAuth=====>');

 // Component to protect routes
 const ProtectedRoute = ({ element }) => {
   return isAuth ? element : <Navigate to="/" replace />;
 };

 // Component to handle unauthenticated routes (like Login)
 const AuthRoute = ({ element }) => {
   return isAuth ? <Navigate to="/Home" replace /> : element;
 };

 const handleLogoutClick = () => {
  // Here you can add the download logic, such as redirecting to a download page or triggering a download
  // console.log('App download initiated');
  localStorage.removeItem("token");

  navigate("/")
  window.location.reload();
};
  return (
    <>
      {/* Conditionally render the top container only if not on the login route */}
      {location.pathname !== "/" && (
        <div className='container-fluid' style={{ background: "#000", color: "white", top: 0 }}>
          <div className='row'>
            <div className='col-lg-2 col-sm-12 col-md-6'>
              <div className='p-2 logo1'>
                <img src={logo} style={{ backgroundSize: "cover", height: "100%", width: "100%", borderRadius: "10px" }} alt="Logo" />
              </div>
            </div>
            <div className='col-lg-8 col-sm-12 col-md-6 p-3 serachInput' >
              <div className={`pb-2 ps-2 ${isFocused ? 'shadow' : ''}`} style={{ border: "1px solid #fff", borderRadius: "5px" ,}}>
                <CiSearch size={20} style={{ color: "#fff" }} />
                <input
                  className='ps-2'
                  placeholder='Enter your name'
                  style={{  background: "none", outline: "none", border: "none", color: "#fff" }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <style>
                  {`
                    ::placeholder { 
                      color: #fff; 
                    }
                  `}
                </style>
              </div>
            </div>
            <div className='col-lg-2 col-sm-12 col-md-6'>
              <NavLink  onClick={()=>{handleLogoutClick()}} className='mt-3 p-2 log' style={{ background: "none", justifyContent: "flex-end", margin: 10 }}>
                <IoIosLogOut size={25} />
                <li className={""} style={{ textDecoration: "none", listStyle: "none", paddingLeft: 10, fontSize: 17, fontWeight: "600" }}>logOut</li>
              </NavLink>
            </div>
          </div>
        </div>
      )}

      <Routes>
      <Route path="/" element={isAuth?<Navigate to={'/Home'} replace />:<Login/>} />

        <Route path="/Home" element={<ProtectedRoute element={<Home />}/>} />
        <Route path="/Upload" element={<ProtectedRoute element={<Upload/>} />} />
        <Route path="/AddSubCategoryData" element={<ProtectedRoute element={<AddSubCategoryData/>} />} />
        <Route path="/AllCatergery" element={<ProtectedRoute element={<NewCategory/>} />} />
        <Route path="/AddCategory" element={<ProtectedRoute element={<AddCategory/>} />} />
      </Routes>
    </>
  );
}

export default App;
